import React, { useState, useEffect } from 'react'

import { Box } from 'design-system'

import SmallAnchor from 'partials/small-anchor'

import FormCheckboxes from '../form-checkboxes'
import GoodbyeScreen from '../goodbye-screen'

const UnsubscribeFeedback = ({
  handleUnsubscribeFeedback,
  topics = [],
  resetUrl,
  onComplete
}) => {
  const [currentTopic, setCurrentTopic] = useState(topics[0] || null)
  const [userChoices, setUserChoices] = useState({})

  useEffect(
    () => {
      currentTopic && createAnswersObj()

      !currentTopic && onComplete()
    },
    [currentTopic]
  )

  const createAnswersObj = () =>
    setUserChoices({ question: currentTopic.question, answers: [] })

  const updateAnswers = choice => {
    const updatedChoices = {
      ...userChoices,
      answers:
        userChoices.answers.indexOf(choice) > -1
          ? userChoices.answers.filter(answer => answer !== choice)
          : [...userChoices.answers, choice]
    }
    setUserChoices(updatedChoices)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    handleUnsubscribeFeedback(userChoices)
    nextQuestion()
  }

  const nextQuestion = () =>
    setCurrentTopic(isLastTopic() ? null : topics[currentTopic['topic_id'] + 1])

  const isLastTopic = () => currentTopic['topic_id'] + 1 > topics.length

  return (
    <Box display="flex" flexDirection="column" textAlign="center">
      {currentTopic ? (
        <FormCheckboxes
          totalQuestions={topics.length}
          currentTopic={currentTopic}
          updateAnswers={updateAnswers}
          handleSubmit={handleSubmit}
        />
      ) : (
        <GoodbyeScreen />
      )}

      <Box textAlign="center">
        <SmallAnchor
          text={currentTopic?.resub_text || 'Come back to us'}
          url={resetUrl}
        />
      </Box>
    </Box>
  )
}

export default UnsubscribeFeedback
