import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'design-system'
import IconLoading from 'partials/icon-loading'

const Overlay = ({ isVisible }) => (
  <Box
    bg="rgba(255, 255, 255, 0.8)"
    bottom={0}
    left={0}
    position="fixed"
    right={0}
    top={0}
    zIndex={1}
    opacity={isVisible ? 1 : 0}
    css={`
      transition: all 150ms ease-out;
      visibility: ${isVisible ? 'visible' : 'hidden'};
    `}
  >
    <IconLoading
      color="tertiary"
      left="50%"
      offset={24}
      position="fixed"
      size={12}
      top="50%"
      css={`
        transform: translate3d(-50%, -50%, 0);
      `}
    />
  </Box>
)

Overlay.defaultProps = {
  isVisible: false
}

Overlay.propTypes = {
  isVisible: PropTypes.bool
}

export default Overlay
