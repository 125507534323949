import React from 'react'
import IconBase from 'assets/icon-base'

const IconCheckmark = props => (
  <IconBase {...props} viewBox="0 0 95.453 100">
    <path d="M94.6 27.5L31.7 86.2l-.5-.5-12.4-13.3L.6 52.8l13.5-12.6 18.3 19.6 49.4-46.1z" />
  </IconBase>
)

export default IconCheckmark
