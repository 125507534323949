import React from 'react'
import { withTheme } from 'emotion-theming'

import Anchor from 'partials/anchor'

import style from './style'

const SmallAnchor = ({ url, text, theme }) => {
  return (
    <Anchor url={url} className={style.base(theme)}>
      {text}
    </Anchor>
  )
}

export default withTheme(SmallAnchor)
