import React from 'react'
import { rem } from 'polished'

import { Box } from 'design-system'
import Button from '../button'

const SubscribeAllButton = ({ handleSelectAll, totalVerticals }) => (
  <Box
    mb={4}
    mx="auto"
    display="flex"
    alignItems="center"
    justifyContent="space-around"
  >
    <Button height={rem(48)} onClick={handleSelectAll} width={180} pt={1}>
      {`Select all (${totalVerticals})`}
    </Button>
  </Box>
)

export default SubscribeAllButton
