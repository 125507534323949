import React from 'react'
import { Box, Toronto } from 'design-system'

import { Primary as PrimaryButton } from 'partials/buttons'
import Checkbox from 'partials/checkbox'

const FormCheckboxes = ({
  totalQuestions,
  currentTopic,
  updateAnswers,
  handleSubmit
}) => {
  return (
    <Box
      is="form"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      width={['80vw', null, '40vw']}
      maxWidth="580px"
      mx="auto"
      mb={30}
    >
      <Toronto letterSpacing="sm" fontWeight="bold" mt={2} mx="auto">
        {`${currentTopic['topic_id'] + 1}/${totalQuestions}`}
      </Toronto>
      <Toronto mb={3} mt={2} mx="auto" width="90%">
        {`${currentTopic.question}  Please select all that apply.`}
      </Toronto>
      {currentTopic.answers.map(answer => (
        <Checkbox
          key={answer}
          label={answer}
          onChange={(_, label) => updateAnswers(label)}
        />
      ))}
      <Box textAlign="center" width="100%" m="10px 0">
        <PrimaryButton text="Submit" onClick={handleSubmit} />
      </Box>
    </Box>
  )
}

export default FormCheckboxes
