import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { rem } from 'polished'
import { parse } from 'query-str-es5'

import { Box, Toronto } from 'design-system'

import Base from 'partials/_base'

import SubscriptionLogic from './subscription-logic'

import Button from './components/button'
import EmailCard from './components/email-card'
import FormRegister from './components/form-register'
import LogoStylistEmails from './components/logo-stylist-emails'
import Message from './components/message'
import Overlay from './components/overlay'
import SubscriptionConfirmation from './components/subscription-confirmation'
import UnsubscribeConfirmation from './components/unsubscribe-confirmation'
import UnsubscribeFeedback from './components/unsubscribe-feedback'
import SubscribeAllButton from './components/button-subscribe-all'

import { contains } from './helpers'
import { emails } from 'root/config'

import { buildQuery } from 'utils/object'

const Emails = ({
  form,
  message,
  showMessage,
  showSubscriptionConfirmation,
  showUnsubscribeConfirmation,
  status,
  type,
  selectedVerticals,
  disabledVerticals,
  handleFormInputChange,
  handleFormSubmit,
  handleUnsubscribe,
  handleUnsubscribeConfirmation,
  handleUnsubscribeCancellation,
  handleUnsubscribeFeedback,
  handleSubscriptionEdit,
  handleSubscriptionConfirmation,
  handleVerticalSelection,
  handleSelectAllVerticals,
  handleSingleUnsubscribe,
  verticals,
  unsubQuestions,
  campaignId,
  ...props
}) => {
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)
  const [resetUrl, setResetUrl] = useState('')

  useEffect(() => {
    const queryParams = parse(window.location.search)

    setShowQuestionnaire(Boolean(queryParams?.verticalIdUnsubscribing))
  }, [])

  useEffect(() => {
    let queryParams = parse(window.location.search)

    if (queryParams.verticalIdUnsubscribing) {
      delete queryParams.verticalIdUnsubscribing
    }

    setResetUrl(buildQuery(queryParams))
  }, [])

  const handleUnsubscribeViaQuestionnaire = () => {
    const queryParams = parse(window.location.search)
    const idToUnsubscribeFrom = queryParams?.verticalIdUnsubscribing

    if (!idToUnsubscribeFrom) return

    handleSingleUnsubscribe(idToUnsubscribeFrom)
  }

  return (
    <Base
      link="https://www.stylist.co.uk/emails"
      showFooter={false}
      showHeader={false}
      {...props}
    >
      <Helmet>
        <title>{emails.title}</title>
        <meta name="description" content={emails.description} />
        <meta name="twitter:title" property="og:title" content={emails.title} />
        <meta
          name="twitter:description"
          property="og:description"
          content={emails.description}
        />
      </Helmet>
      {__TELEMETRY_MAINTENANCE_MODE__ && (
        <Box
          display="flex"
          height="100vh"
          width="100vw"
          p={4}
          textAlign="center"
        >
          <Box m="auto" maxWidth={500}>
            <LogoStylistEmails mb={4} mx="auto" width={['50vw', 200, 260]} />
            <Toronto
              fontSize={['xs', 'sm']}
              fontWeight="semibold"
              letterSpacing="sm"
            >
              {
                'Stylist emails are currently undergoing maintenance, please try again later'
              }
            </Toronto>
          </Box>
        </Box>
      )}
      {!__TELEMETRY_MAINTENANCE_MODE__ && (
        <Box
          maxWidth={1160}
          mb={[type === 'new' ? 6 : 5, null, 5]}
          mx="auto"
          pb={3}
          pt={['15vw', 5]}
          px={[3, null, 4, 5]}
        >
          <LogoStylistEmails mb={4} mx="auto" width={['50vw', 200, 260]} />
          {showQuestionnaire ? (
            <UnsubscribeFeedback
              handleUnsubscribeFeedback={handleUnsubscribeFeedback}
              topics={unsubQuestions}
              resetUrl={resetUrl}
              onComplete={handleUnsubscribeViaQuestionnaire}
            />
          ) : (
            <>
              <Box maxWidth={580} mb={4} mx="auto" width="85vw">
                {status !== 'complete' &&
                  status !== 'unsubscribed' && (
                    <Toronto
                      textAlign="center"
                      letterSpacing="sm"
                      fontWeight="semibold"
                    >
                      {type === 'new'
                        ? emails.description
                        : 'Welcome back! This is the portal where you can sign up for new emails and unsubscribe to the ones you no longer want to hear from.'}
                    </Toronto>
                  )}
              </Box>
              {status === 'complete' && (
                <Toronto
                  textAlign="center"
                  fontSize={['sm', null, 'md']}
                  mb={3}
                >
                  {'Thanks for signing up!'}
                </Toronto>
              )}
              {status !== 'unsubscribed' && (
                <>
                  <Toronto
                    letterSpacing="sm"
                    fontWeight="semibold"
                    mb={4}
                    mx="auto"
                    textAlign="center"
                    width={['80vw', null, null, '100%']}
                  >
                    {status === 'complete'
                      ? 'Here are the emails you’ll be receiving:'
                      : type === 'new'
                        ? 'Choose the emails you’d like to receive:'
                        : 'Update your email preferences:'}
                  </Toronto>
                  {status !== 'complete' && (
                    <>
                      <SubscribeAllButton
                        totalVerticals={verticals.length}
                        handleSelectAll={handleSelectAllVerticals}
                      />
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="center"
                      >
                        {verticals.map(vertical => (
                          <EmailCard
                            {...vertical}
                            handleSelection={handleVerticalSelection}
                            isDisabled={contains(
                              vertical.telemetry_vertical,
                              disabledVerticals
                            )}
                            isSelected={contains(
                              vertical.telemetry_vertical,
                              selectedVerticals
                            )}
                            key={vertical.telemetry_vertical}
                          />
                        ))}
                      </Box>
                    </>
                  )}
                </>
              )}
              {status === 'complete' && (
                <>
                  <Box display="flex" flexWrap="wrap" justifyContent="center">
                    {verticals
                      .filter(({ telemetry_vertical }) =>
                        contains(telemetry_vertical, selectedVerticals)
                      )
                      .map(vertical => (
                        <EmailCard
                          key={vertical.telemetry_vertical}
                          name={vertical.name}
                          parent={vertical.parent}
                          primary_colour={vertical.primary_colour}
                          telemetry_vertical={vertical.telemetry_vertical}
                          thumbnail_image={vertical.thumbnail_image}
                          crop={vertical.crop}
                          image={vertical.image}
                        />
                      ))}
                  </Box>
                  <Box textAlign="center" mb={4}>
                    <Toronto mb={3} letterSpacing="sm" fontWeight="semibold">
                      {'Want to add or remove any emails?'}
                    </Toronto>
                    <Button
                      height={rem(32)}
                      onClick={handleSubscriptionEdit}
                      pt={rem(4)}
                    >
                      {'Edit this list'}
                    </Button>
                  </Box>
                </>
              )}
              {type === 'existing' &&
                status !== 'unsubscribed' &&
                status !== 'complete' && (
                  <Box
                    p={[4, null, 5]}
                    textAlign="center"
                    css={`
                      margin-bottom: ${rem(48)};
                    `}
                  >
                    <Toronto
                      fontSize={['sm', null, 'md']}
                      mb={3}
                      ml={[0, null, null, '200px']}
                      mr={[0, null, null, '200px']}
                    >
                      {showUnsubscribeConfirmation
                        ? 'Are you sure?'
                        : 'If you no longer wish for us to send you any of the above emails, please click the link below'}
                    </Toronto>
                    <UnsubscribeConfirmation
                      handleUnsubscribe={handleUnsubscribe}
                      handleUnsubscribeCancellation={
                        handleUnsubscribeCancellation
                      }
                      handleUnsubscribeConfirmation={
                        handleUnsubscribeConfirmation
                      }
                      isVisible={showUnsubscribeConfirmation}
                    />
                  </Box>
                )}

              {status !== 'complete' &&
                type === 'new' && (
                  <FormRegister
                    form={form}
                    isVisible={Boolean(selectedVerticals.length)}
                    onInputChange={handleFormInputChange}
                    onSubmit={handleFormSubmit}
                    status={status}
                    type={type}
                  />
                )}

              {status !== 'complete' &&
                status !== 'unsubscribed' &&
                type === 'existing' && (
                  <SubscriptionConfirmation
                    isVisible={showSubscriptionConfirmation}
                    onClick={handleSubscriptionConfirmation}
                  />
                )}

              {status === 'unsubscribed' && (
                <UnsubscribeFeedback
                  handleUnsubscribeFeedback={handleUnsubscribeFeedback}
                  topics={unsubQuestions}
                  resetUrl={resetUrl}
                  onComplete={handleUnsubscribeViaQuestionnaire}
                />
              )}

              <Overlay isVisible={status === 'loading'} />
              <Message isVisible={showMessage}>{message}</Message>
            </>
          )}
        </Box>
      )}
    </Base>
  )
}

Emails.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
  disabledVerticals: PropTypes.array,
  selectedVerticals: PropTypes.array
}

export default ({ verticals, unsubQuestions }) => (
  <SubscriptionLogic verticals={verticals} unsubQuestions={unsubQuestions}>
    {props => <Emails {...props} />}
  </SubscriptionLogic>
)
