import React from 'react'
import { rem } from 'polished'
import { withTheme } from 'emotion-theming'

import { Box } from 'design-system'
import IconLoading from 'partials/icon-loading'
import { primary_color } from '../../helpers'

import Link from '../link'
import Button from '../button'

import { shade } from 'style/utils/color'

const UnsubscribeConfirmation = ({
  handleUnsubscribe,
  handleUnsubscribeCancellation,
  handleUnsubscribeConfirmation,
  isVisible,
  theme
}) => (
  <Box
    alignItems="center"
    display="flex"
    justifyContent="center"
    css={`
      flex-direction: column;
      @media (min-width: 420px) {
        flex-direction: row;
      }
    `}
  >
    {isVisible && (
      <Button
        height={rem(32)}
        onClick={handleUnsubscribeCancellation}
        pt={rem(4)}
        mb={2}
        width={160}
        css={`
          border: 0;
          cursor: pointer;
          &:hover,
          &:focus {
            background-color: ${shade(primary_color, 0.4)};
            outline: none;
          }
          @media (min-width: 420px) {
            margin: 0 ${isVisible && theme.space[2]} 0 0;
          }
        `}
      >
        {'Cancel'}
      </Button>
    )}
    <Link
      textDecoration="underline"
      height={rem(32)}
      onClick={isVisible ? handleUnsubscribeConfirmation : handleUnsubscribe}
      pt={rem(6)}
      width={160}
      css={`
        border: 0;
        cursor: pointer;
        @media (min-width: 420px) {
          margin-left: ${isVisible && theme.space[2]};
        }
      `}
    >
      {isVisible ? (
        'Yes I’m sure'
      ) : status === 'loading' ? (
        <IconLoading />
      ) : (
        'UNSUBSCRIBE ALL'
      )}
    </Link>
  </Box>
)

export default withTheme(UnsubscribeConfirmation)
