import React from 'react'
import PropTypes from 'prop-types'
import { rem } from 'polished'

import { BackgroundImage, Box, Toronto, Canberra, Warsaw } from 'design-system'
import IconCheckmark from 'assets/icon-checkmark'
import IconLoading from 'partials/icon-loading'
import { getCropRatio, detectFormat } from 'utils/image'
import { primary_color } from '../../helpers'

import Link from '../link'
import Button from '../button'

const DEFAULT_RATIO = 1

const EmailCard = ({
  delivered,
  description,
  handleSelection,
  isDisabled,
  isSelected,
  most_recent_email_id,
  name,
  parent,
  primary_colour,
  telemetry_vertical,
  thumbnail_image,
  crop,
  image
}) => (
  <Box
    mb={4}
    position="relative"
    px={[0, 1, 2]}
    textAlign="center"
    width={['100%', 1 / 2, 1 / 3]}
  >
    {isSelected && (
      <Button
        height={rem(32)}
        pt={rem(4)}
        px={2}
        mx={2}
        position="absolute"
        right="2%"
        top="2%"
        zIndex={1}
        onClick={() => !isDisabled && handleSelection(telemetry_vertical)}
      >
        <IconCheckmark
          customStyle={`
            height: ${rem(18)};
            margin-top: ${rem(-2)};
            width: ${rem(18)};
          `}
        />
      </Button>
    )}
    <BackgroundImage
      ratio={getCropRatio(crop) || DEFAULT_RATIO}
      /**
       * 1. use the original URL for gifs because CMS freezes them when resizing
       * 2. fallback to original URL if crop provided does not exist
       */
      url={
        detectFormat(thumbnail_image, 'gif')
          ? thumbnail_image
          : image?.sizes?.[crop] || thumbnail_image
      }
    />
    <Box
      p={3}
      pt={['1.5rem', parent === 'stylist-loves' ? 3 : '1.5rem', '1.5rem']}
    >
      <Box
        alignItems={['baseline', 'center', 'baseline']}
        display="flex"
        flexDirection={['row', 'column', 'row']}
        justifyContent="center"
        mb={3}
        mx={rem(-16)}
      >
        {parent === 'stylist-loves' && (
          <Toronto
            color={primary_colour}
            fontSize={rem(18)}
            mb={[0, 1, 0]}
            mr={2}
          >
            {'Stylist Loves'}
          </Toronto>
        )}
        <Warsaw fontWeight="semibold" fontSize="md">
          {name}
        </Warsaw>
      </Box>
      {delivered && (
        <Canberra fontSize="xxxs" letterSpacing="xxl" mb={3}>
          {`Delivered ${delivered}`}
        </Canberra>
      )}
      {description && (
        <Toronto
          fontSize="xxxs"
          fontWeight="regular"
          lineHeight="md"
          maxWidth={380}
          mb={3}
          mx="auto"
        >
          {description}
        </Toronto>
      )}
      {handleSelection && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          {most_recent_email_id && (
            <Link
              color={primary_color}
              mb={3}
              to={`/email/${most_recent_email_id}`}
            >
              {'View latest >'}
            </Link>
          )}
          {isDisabled ? (
            <IconLoading />
          ) : isSelected ? (
            <Link
              textTransform="uppercase"
              textDecoration="underline"
              onClick={() => !isDisabled && handleSelection(telemetry_vertical)}
              css={`
                cursor: pointer;
              `}
            >
              {'Unsubscribe'}
            </Link>
          ) : (
            <Button
              height={rem(32)}
              pt={rem(4)}
              px={3}
              mx={2}
              onClick={() => !isDisabled && handleSelection(telemetry_vertical)}
              width={rem(120)}
            >
              {'Sign me up'}
            </Button>
          )}
        </Box>
      )}
    </Box>
  </Box>
)

EmailCard.propTypes = {
  category: PropTypes.string,
  delivered: PropTypes.string,
  handleSelection: PropTypes.func,
  id: PropTypes.number,
  image: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  name: PropTypes.string,
  sell: PropTypes.string,
  slug: PropTypes.string
}

export default EmailCard
