import React from 'react'
import { Box, Toronto } from 'design-system'
import Button from '../button'
import Link from '../link'
import { shade } from 'style/utils/color'
import { primary_color } from '../../helpers'

const SubscriptionConfirmation = ({ isVisible, ...rest }) => (
  <Box
    bg="tertiary"
    bottom={0}
    left={0}
    p={3}
    position="fixed"
    right={0}
    zIndex={2}
    css={`
      transform: translateY(${isVisible ? 0 : '100%'});
      transition: all 200ms ease-out;
      visibility: ${isVisible ? 'visible' : 'hidden'};
    `}
  >
    <Box
      alignItems="center"
      display="flex"
      flexWrap={['wrap', null, 'nowrap']}
      justifyContent="center"
    >
      <Toronto
        color="white"
        flex={['none', null, '0 1 auto']}
        flexBasis={[null, null, '40%']}
        fontSize={['xs', 'sm']}
        fontWeight="semibold"
        mb={[3, 3, 0]}
        pr={[null, null, 3]}
        textAlign={'center'}
        width={['75vw', '100%', '30%']}
      >
        {'To confirm these changes, click here:'}
      </Toronto>
      <Button
        fontSize="xxs"
        lineHeight="md"
        mr={3}
        py="15px"
        width={['100%', '50%', '30%']}
        css={`
          border: 0;
          cursor: pointer;
          &:hover,
          &:focus {
            background-color: ${shade(primary_color, 0.4)};
            outline: none;
          }
        `}
        {...rest}
      >
        {'Update my preferences'}
      </Button>
      <Link
        to="https://www.stylist.co.uk/about/privacy-policy"
        color="white"
        textDecoration="underline"
        fontSize="xxs"
        fontWeight="normal"
        textAlign={['center', 'right']}
        pt={[3, 10]}
        width={['100%', '10%']}
      >
        {'Privacy Policy'}
      </Link>
    </Box>
  </Box>
)

export default SubscriptionConfirmation
