import React from 'react'
import { withTheme } from 'emotion-theming'

import style from './style'

const Checkbox = ({ label, checked = false, onChange, theme }) => {
  return (
    <label className={style.base} htmlFor={label}>
      <input
        id={String(label)}
        type="checkbox"
        name="checkbox"
        onChange={() => onChange(!checked, label)}
        className={style.input}
      />
      <div
        className={style.label(theme)}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </label>
  )
}

export default withTheme(Checkbox)
