import React from 'react'
import { Toronto } from 'design-system'

const Link = ({ to, children, ...rest }) => (
  <Toronto
    is="a"
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    color="black"
    fontSize="xxxs"
    mx={2}
    {...rest}
  >
    {children}
  </Toronto>
)

export default Link
