import React from 'react'
import PropTypes from 'prop-types'
import { rem } from 'polished'

import { Toronto } from 'design-system'

const Message = ({ children, isVisible, ...rest }) => (
  <Toronto
    bg="tertiary"
    color="white"
    fontSize={['xxs', null, 'xs']}
    fontWeight="semibold"
    is="div"
    left="50%"
    letterSpacing="sm"
    lineHeight={1.4}
    maxWidth="80vw"
    pb={[rem(8), null, 3]}
    position="fixed"
    pt={[rem(9), null, rem(18)]}
    px={[3, null, 4]}
    textAlign="center"
    top={rem(16)}
    width="fit-content"
    zIndex={10}
    css={`
      transform: translate3d(-50%, ${isVisible ? 0 : 'calc(-100% - 12px)'}, 0);
      transition: all 200ms ease-out;
      visibility: ${isVisible ? 'visible' : 'hidden'};
    `}
    {...rest}
  >
    {children}
  </Toronto>
)

Message.defaultProps = {
  isVisible: false
}

Message.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool
}

export default Message
