import { Component } from 'react'
import { stringify, parse } from 'query-str-es5'

import { bugsnag } from '_utility/error-boundary'

import {
  api,
  getMessage,
  getSessionData,
  isValidSID,
  GET,
  POST,
  TEAM_ID
} from 'utils/telemetry'
import generateToken from 'utils/telemetry/generate-token'
import { trackEvent } from 'utils/analytics-trackers'

import { contains, scrollToTop } from '../helpers'

class SubscriptionLogic extends Component {
  state = {
    form: { email: '' },
    showSubscriptionConfirmation: false,
    status: 'ready',
    type: 'new',
    selectedVerticals: [],
    disabledVerticals: []
  }

  messageTimeout = 5000

  componentDidMount() {
    if (__TELEMETRY_MAINTENANCE_MODE__ === 'true') return
    // extract the secret ID from query params
    const { sid, cid } = parse(window.location.search)
    // no need to set state if not existing
    if (!isValidSID(sid)) return
    // set "existing user" state
    this.setState(
      () => ({
        secretId: sid,
        campaignId: cid,
        status: 'loading',
        type: 'existing'
      }),
      this.getMultipleVerticalSubscription
    )
  }

  getMultipleVerticalSubscription = () => {
    // fetch user preferences and save to UI
    // will only be called from existing user so set 'ready' once complete
    GET(`${api.preferences}/${this.state.secretId}`)
      .then(res => this.setMultipleVerticalSelection(res.data))
      .then(() => this.setState({ status: 'ready' }))
      .catch(err => {
        this.setState({ status: 'ready', type: 'new' })
        this.setErrorMessage('critical')
        bugsnag.notify(err)
      })
  }

  setMultipleVerticalSubscription = () => {
    // create payload to match Telemetrys API spec
    const payload = {
      referrer: getSessionData({
        campaign: 'stylist-website',
        internalCta: 'stylist-emails-hub',
        source: 'stylist'
      }),
      verticalIds: this.state.selectedVerticals,
      ...this.state.form
    }

    // make call to sign up API, passing token
    POST(api.signup, {
      ...payload,
      token: generateToken(this.state.form.email)
    })
      .then(res => {
        trackEvent(
          'Add new subscriber',
          'Email Preferences',
          String(this.state.selectedVerticals)
        )
        // once complete we can save the secretId and let them edit preferences
        return this.setState(
          {
            status: 'complete',
            secretId: res.data.secretID,
            type: 'existing'
          },
          scrollToTop
        )
      })
      .catch(err => {
        this.setState({ status: 'ready' })
        this.setErrorMessage(err.message)
        bugsnag.notify(err)
      })
  }

  handleUnsubscribe = () => {
    this.setState({ showUnsubscribeConfirmation: true })
  }
  handleUnsubscribeCancellation = () => {
    this.setState({ showUnsubscribeConfirmation: false })
  }
  handleUnsubscribeConfirmation = () => {
    this.setState({ status: 'loading' })
    // send request to unsubscribe from all verticals
    // and remove from Telemetry
    POST(api.unsubscribeTeam, {
      secretId: this.state.secretId,
      campaignId: this.state.campaignId,
      teamId: TEAM_ID
    })
      .then(() => {
        trackEvent(
          'Remove subscriber',
          'Email Preferences',
          __TELEMETRY_TEAM_ID__
        )
        // remove user from Telemetry
        this.setState(
          { status: 'unsubscribed', showUnsubscribeConfirmation: false },
          scrollToTop
        )
      })
      .catch(() => {
        this.setState({ status: 'ready' })
        this.setErrorMessage('critical')
      })
  }

  handleUnsubscribeFeedback = async userAnswers => {
    const payload = { ...userAnswers }
    if (this.state.campaignId) payload.campaignId = this.state.campaignId
    await POST(api.unsubscribeFeedback, {
      ...payload,
      token: generateToken(this.state.form.email)
    })
  }

  handleSubscriptionEdit = () => {
    const { pathname, search } = window.location || {}

    if (!parse(search).hasOwnProperty('sid')) {
      window.history.replaceState(
        {},
        document.title,
        stringify(
          {
            itm_cta: 'edit-preferences',
            itm_source: 'stylist',
            sid: this.state.secretId
          },
          pathname
        )
      )
    }
    this.setState({ status: 'ready' }, scrollToTop)
  }

  handleSubscriptionConfirmation = () => {
    this.setState(
      {
        showSubscriptionConfirmation: false,
        showUnsubscribeConfirmation: false,
        status: 'complete'
      },
      scrollToTop
    )
  }

  toggleVerticalSubscription = id => {
    const {
      selectedVerticals,
      disabledVerticals,
      secretId,
      campaignId
    } = this.state
    // hide previous error state
    this.setState({ showMessage: false })

    const isSelected = contains(id, selectedVerticals)
    const isDisabled = contains(id, disabledVerticals)
    // if the user is trying to unsubscribe from their last vertical
    // point them towards the unsubscribe button
    if (isSelected && selectedVerticals.length === 1) {
      return this.setErrorMessage('unsubscribe')
    }
    // switch between subscribe and unsubscribe depending
    // on subscription status
    const endpoint = isSelected ? api.unsubscribe : api.subscribe
    // stop multi clicks
    if (!isDisabled) this.disableVertical(id)
    // go and talk to telem
    POST(endpoint, {
      secretId: secretId,
      verticalId: id,
      campaignId: campaignId
    })
      .then(res => this.toggleVerticalSelection(res.data.verticalID))
      .then(() => this.handleVerticalSubscription(id))
      .catch(err => {
        this.setErrorMessage(err.message)
        this.handleVerticalSubscription(id)
        bugsnag.notify(err)
      })
  }

  handleVerticalSubscription = id =>
    setTimeout(() => this.enableVertical(id), 250)

  setErrorMessage = status => {
    trackEvent(
      'Subscription error',
      'Email Preferences',
      `${status}: ${getMessage(status)}`
    )
    this.setState({ message: getMessage(status), showMessage: true }, () =>
      setTimeout(
        () => this.setState({ showMessage: false }),
        this.messageTimeout
      )
    )
  }

  enableVertical = id =>
    this.setState(({ disabledVerticals }) => ({
      showSubscriptionConfirmation: true,
      disabledVerticals: disabledVerticals.filter(currId => currId !== id)
    }))
  disableVertical = id =>
    this.setState(({ disabledVerticals }) => ({
      disabledVerticals: [...disabledVerticals, id]
    }))

  setMultipleVerticalSelection = ids =>
    // set all ids matching telemetry team
    this.setState({
      selectedVerticals: ids.map(({ id }) => id)
    })

  toggleVerticalSelection = id =>
    // either add or remove vertical ID from selectedVerticals
    this.setState(({ selectedVerticals }) => ({
      selectedVerticals: contains(id, selectedVerticals)
        ? selectedVerticals.filter(currId => currId !== id)
        : [...selectedVerticals, id]
    }))

  handleVerticalSelection = id => {
    const { type } = this.state
    // no need to talk to API until all confirmed
    // just toggle IDs if a new user
    if (type === 'new') this.toggleVerticalSelection(id)
    // talk to API to subscribe/unsubscribe
    if (type === 'existing') this.toggleVerticalSubscription(id)
  }

  handleSelectAllVerticals = async () => {
    const { selectedVerticals } = this.state
    const allVerticalIds = this.props.verticals.map(
      vert => vert.telemetry_vertical
    )

    // if ALL are selected, deselect all
    if (allVerticalIds.length === selectedVerticals.length)
      return allVerticalIds.forEach(this.handleVerticalSelection)

    // find and select the verticals not yet selected
    allVerticalIds.forEach(id => {
      if (!selectedVerticals.includes(id)) this.handleVerticalSelection(id)
    })
  }

  handleFormInputChange = e => {
    e.preventDefault()
    this.setState({
      form: { [e.target.name]: e.target.value }
    })
  }
  handleFormSubmit = e => {
    e.preventDefault()
    // set loading state for UI and hide previous error state
    this.setState(
      { status: 'loading', showMessage: false },
      // send subscription data to API
      this.setMultipleVerticalSubscription
    )
  }

  handleSingleUnsubscribe = id => {
    const { secretId, campaignId } = this.state

    POST(api.unsubscribe, {
      secretId: secretId,
      verticalId: id,
      campaignId: campaignId
    }).catch(err => {
      this.setErrorMessage(err.message)
      bugsnag.notify(err)
    })
  }

  render() {
    return this.props.children({
      handleFormInputChange: this.handleFormInputChange,
      handleFormSubmit: this.handleFormSubmit,
      handleSubscriptionConfirmation: this.handleSubscriptionConfirmation,
      handleSubscriptionEdit: this.handleSubscriptionEdit,
      handleUnsubscribe: this.handleUnsubscribe,
      handleUnsubscribeCancellation: this.handleUnsubscribeCancellation,
      handleUnsubscribeConfirmation: this.handleUnsubscribeConfirmation,
      handleUnsubscribeFeedback: this.handleUnsubscribeFeedback,
      handleVerticalSelection: this.handleVerticalSelection,
      handleSelectAllVerticals: this.handleSelectAllVerticals,
      handleSingleUnsubscribe: this.handleSingleUnsubscribe,
      verticals: this.props.verticals,
      unsubQuestions: this.props.unsubQuestions,
      ...this.state
    })
  }
}

export default SubscriptionLogic
