import { css } from 'emotion'

const base = css`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 16px;
`

const span = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #000;
  text-align: center;
  font-size: 16px;
`

const input = css`
  height: 20px;
  width: 20px;
  margin: 6px 12px 6px 0;
`

const label = theme => css`
  font-family: ${theme.fonts.HurmeGeometricSans3};
`

export default {
  base,
  span,
  input,
  label
}
