import React from 'react'

import { Toronto, Canberra } from 'design-system'

import Anchor from 'partials/anchor'
import HomepageAnchor from 'higher-order/homepage-anchor'

const GoodbyeScreen = () => (
  <>
    <Toronto
      letterSpacing="sm"
      fontWeight="semibold"
      mt={3}
      mb={4}
      mx="auto"
      width="90%"
    >
      {'No hard feelings. Remember you can still:'}
    </Toronto>
    <Canberra fontSize="xxs" mb={3}>
      <Anchor
        url={'https://www.instagram.com/stylistmagazine'}
        css={`
          color: #3dd2be;
          text-decoration: none;
          &:hover,
          &:focus {
            color: #378a7f;
          }
        `}
      >
        {'Follow us on Instagram'}
      </Anchor>
    </Canberra>
    <Canberra fontSize="xxs" mb={3}>
      <HomepageAnchor
        forceAbsolute
        css={`
          color: #3dd2be;
          text-decoration: none;
          &:hover,
          &:focus {
            color: #378a7f;
          }
        `}
      >
        {"Catch up on today's headlines"}
      </HomepageAnchor>
    </Canberra>
    <Canberra fontSize="xxs" mb={3}>
      <Anchor
        url={
          'https://www.newsstand.co.uk/256-Womens-Weekly-Magazines/13811-Subscribe-to-STYLIST-Magazine-Subscription.aspx'
        }
        css={`
          color: #3dd2be;
          text-decoration: none;
          &:hover,
          &:focus {
            color: #378a7f;
          }
        `}
      >
        {'Get Stylist delivered to your door'}
      </Anchor>
    </Canberra>
  </>
)

export default GoodbyeScreen
