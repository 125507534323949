import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { rem } from 'polished'

import { Cardiff, Toronto, Box } from 'design-system'
import IconLoading from 'partials/icon-loading'

import Button from '../button'
import Link from '../link'

class FormRegister extends Component {
  shouldComponentUpdate = nextProps => {
    return (
      nextProps.form.email !== this.props.form.email ||
      nextProps.status !== this.props.status ||
      nextProps.isVisible !== this.props.isVisible
    )
  }
  render() {
    const { status, form, isVisible, onInputChange, onSubmit } = this.props
    return (
      <Box
        bg="tertiary"
        bottom={0}
        left={0}
        p={3}
        position="fixed"
        right={0}
        zIndex={2}
        css={`
          transform: translateY(${isVisible ? 0 : '100%'});
          transition: all 200ms ease-out;
          visibility: ${isVisible ? 'visible' : 'hidden'};
        `}
      >
        <Box
          is="form"
          onSubmit={status !== 'loading' ? onSubmit : e => e.preventDefault()}
          alignItems="center"
          display="flex"
          flexWrap={['wrap', null, 'nowrap']}
          justifyContent="center"
          maxWidth={1016}
          mx="auto"
        >
          <Toronto
            color="white"
            flex={['none', null, '0 1 auto']}
            flexBasis={[null, null, '40%']}
            fontSize={['xs', 'sm']}
            fontWeight="semibold"
            mb={[3, 3, 0]}
            pr={[null, null, 3]}
            textAlign={['center', null, 'left']}
            width={['75vw', '100%', '40%', '52%']}
          >
            {'Finished choosing your preferences?'}
          </Toronto>
          <Cardiff
            is="input"
            bg="white"
            border="transparent"
            borderRadius={0}
            flex="1 0 auto"
            fontSize="xs"
            height={50}
            lineHeight={1}
            maxWidth={300}
            name="email"
            onChange={onInputChange}
            p={3}
            required
            placeholder="Enter your email address"
            pt={18}
            type="email"
            value={form.email}
            width={`calc(100% - ${rem(94)})`} // width of the button & margin
          />
          <Button
            height={50}
            ml={1}
            px={[3, null, null, 4]}
            width={[90, null, 120]}
            type="submit"
          >
            {status === 'loading' ? <IconLoading /> : 'Sign up'}
          </Button>
          <Link
            to="https://www.stylist.co.uk/about/privacy-policy"
            color="white"
            textDecoration="underline"
            fontSize="xxs"
            fontWeight="normal"
            textAlign={['center', 'right']}
            pt={[3]}
            width={['100%', '10%', '20%']}
          >
            {'Privacy Policy'}
          </Link>
        </Box>
      </Box>
    )
  }
}

FormRegister.propTypes = {
  isVisible: PropTypes.bool,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
  form: PropTypes.shape({
    email: PropTypes.string
  })
}

export default FormRegister
