import React from 'react'
import { withTheme } from 'emotion-theming'

import { Box, Canberra } from 'design-system'
import LogoStylist from 'assets/logo-stylist'
import HomepageAnchor from 'higher-order/homepage-anchor'

const StylistEmailsLogo = ({ theme, ...rest }) => (
  <HomepageAnchor forceAbsolute>
    <Box
      color="black"
      display="block"
      textAlign="center"
      css={`
        transition: all 150ms;
        :hover,
        :focus {
          color: ${theme.colors.primary};
        }
      `}
      {...rest}
    >
      <LogoStylist
        css={`
          color: currentColor;
          height: 100%;
          margin-bottom: ${theme.space[2]};
          width: 100%;
        `}
      />
      <Canberra color="primary" fontSize="xxs" letterSpacing="xxl">
        {'Emails'}
      </Canberra>
    </Box>
  </HomepageAnchor>
)

export default withTheme(StylistEmailsLogo)
