import { css } from 'emotion'

const base = theme => css`
  font-size: 16px;
  font-family: ${theme.fonts.HurmeGeometricSans3};
  color: ${theme.colors.black};
`

export default {
  base
}
