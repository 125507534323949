import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'
import { primary_color } from '../../helpers'

import { Canberra } from 'design-system'
import { shade } from 'style/utils/color'

const Button = ({ children, theme, interactColor, css, ...rest }) => (
  <Canberra
    is="button"
    bg={primary_color}
    color="black"
    fontSize="xxxs"
    px={4}
    textAlign="center"
    textDecoration="none"
    css={`
      border: 0;
      cursor: pointer;
      transition: all 125ms ease-out;
      &:hover,
      &:focus {
        background-color: ${interactColor || shade(primary_color, 0.4)};
        outline: none;
      }
    `}
    {...rest}
  >
    {children}
  </Canberra>
)

Button.propTypes = {
  interactColor: PropTypes.string
}

export default withTheme(Button)
